
import {
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
} from "vue";
import { CalendarOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import {
  ExaminerAllocationTaskTypeEnum,
  TimeWindowEnum,
  ExaminerAllocationManualArrangementInput,
  TimetableDto,
} from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message, Form, DatePicker, Button, Modal, Switch } from "ant-design-vue";
import { createVNode } from "vue"; // 引入 h 函数
import { Item } from "ant-design-vue/lib/menu";

const useForm = Form.useForm;
export default defineComponent({
  props: {
    examinerAllocationIdList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  components: {
    CalendarOutlined,
    ExclamationCircleOutlined,
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    var examinerAllocationIdList = ref<string[]>(props.examinerAllocationIdList);
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    const saveLoading = ref<boolean>(false);

    const DeleteExaminer = (refresh: boolean = false) => {
      saveLoading.value=true;
      _Client.examinerAllocationManualArrangementClient
        .deleteExaminerAllocation(examinerAllocationIdList.value)
        .then((_) => {
          message.success("Delete Successfully");
       
        }).finally(() => { saveLoading.value = false; context.emit("close", refresh);});
    };
    return {
      close,
      DeleteExaminer,
      saveLoading
    };
  },
});
