
import {
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
  onMounted,
} from "vue";
import { CalendarOutlined, SmileOutlined } from "@ant-design/icons-vue";
import {
  ExaminerAllocationTaskTypeEnum,
  TimeWindowEnum,
  ExaminerAllocationManualArrangementInput,
  TimetableDto,
} from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message, Form, DatePicker, Button, Modal, Switch } from "ant-design-vue";
import dayjs, { Dayjs } from "dayjs";
import { createVNode } from "vue"; // 引入 h 函数
import { Item } from "ant-design-vue/lib/menu";

const useForm = Form.useForm;
interface EditExamineModel {
  workType: string | undefined;
  examinerId: string | undefined;
  examinerNo: string | undefined;
  examinerName: string | undefined;
  examinerInitial: string | undefined;
  examinerQualification: string | undefined;
  examinerAllocationTaskType: ExaminerAllocationTaskTypeEnum | undefined;
  timeWindow: TimeWindowEnum;
  single: boolean;
  taskNumber: string | undefined;
  startTimeSlotId: string | undefined;
  endTimeSlotId: string | undefined;
  remark: string | undefined;
}
interface ShowEditExamineModel {
  examinerId: string | undefined;
  examinerNo: string | undefined;
  examinerName: string | undefined;
  examinerInitial: string | undefined;
  examinerQualification: string | undefined;
  examinerAllocationTaskType: ExaminerAllocationTaskTypeEnum | undefined;
  taskNumber: string | undefined;
  startTimeSlotId: string | undefined;
  endTimeSlotId: string | undefined;
  remark: string | undefined;
}
export default defineComponent({
  props: {
    examinerList: {
      type: Array as PropType<EditExamineModel[]>,
      default: () => [],
    },
    startTimetableList: {
      type: Array as PropType<TimetableDto[]>,
      default: () => [],
    },
    endTimetableList: {
      type: Array as PropType<TimetableDto[]>,
      default: () => [],
    },
    workDate: { type: String },
  },
  components: {
    CalendarOutlined,
    SmileOutlined,
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    // 显示得值 要求一个考官只显示一条做的特殊处理
    var examinerList = ref<ShowEditExamineModel[]>(
      Array.from(
        new Map(
          props.examinerList.map((item: EditExamineModel) => [
            item.examinerId, // 使用 examinerId 作为唯一键进行去重
            {
              workType: item.workType,
              examinerId: item.examinerId,
              examinerName: item.examinerName,
              examinerNo: item.examinerNo,
              examinerInitial: item.examinerInitial,
              examinerQualification: item.examinerQualification,
              examinerAllocationTaskType: item.examinerAllocationTaskType,
              taskNumber: item.taskNumber,
              startTimeSlotId: item.startTimeSlotId,
              endTimeSlotId: item.endTimeSlotId,
              remark: item.remark,
            } as ShowEditExamineModel,
          ])
        ).values() // 从 Map 中提取去重后的值
      )
    );

    // 实际存储得值
    var saveExaminerList = props.examinerList;
    var startTimetableList = ref<TimetableDto[]>(props.startTimetableList);
    var endTimetableList = ref<TimetableDto[]>(props.endTimetableList);

    const allocationTaskTypeEnum = ExaminerAllocationTaskTypeEnum;
    const uniqueTimeWindows = Array.from(
      new Set(saveExaminerList.map((item) => item.timeWindow))
    );
    var fullDay = false;
    const title = ref<string>("Edit Examiner Task");
    const selectedFullDay = ref(false);
    const saveLoading = ref<boolean>(false);
    const dates = ref<string[]>([]);
    const showDatePicker = ref(false);
    const allocationTaskType = ref(ExaminerAllocationTaskTypeEnum);
    const taskNumber = ref(0);
    const remark = ref("");

    const startTimeSlotId = ref(startTimetableList.value[0].timeSlotId);
    const endTimeSlotId = ref(
      endTimetableList.value[endTimetableList.value.length - 1].timeSlotId
    );

    const single = props.examinerList[0].single;
    if (single) {
      title.value = "Edit Examiner Task-" + uniqueTimeWindows[0];
      remark.value = props.examinerList[0].remark;
      taskNumber.value = props.examinerList[0].taskNumber;
      allocationTaskType.value = props.examinerList[0].examinerAllocationTaskType;
      if (props.examinerList[0].startTimeSlotId != undefined) {
        startTimeSlotId.value = props.examinerList[0].startTimeSlotId;
        endTimeSlotId.value = props.examinerList[0].endTimeSlotId;
      }
    } else {
      var titleTimeWindows = "";
      if (uniqueTimeWindows.length != 1) {
        titleTimeWindows = "Full day";
        fullDay = true;
        startTimetableList.value = props.startTimetableList.filter(
          (s) => s.timeWindow === TimeWindowEnum.AM
        );
        endTimetableList.value = props.endTimetableList.filter(
          (s) => s.timeWindow === TimeWindowEnum.PM
        );
      } else {
        titleTimeWindows = uniqueTimeWindows[0];
      }

      title.value = "Batch Edit Examiner Tasks-" + titleTimeWindows;
    }

    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }

    function onTimeSlotChange() {
      if (fullDay) {
        return;
      }
      var startTime = startTimetableList.value.find(
        (item) => item.timeSlotId === startTimeSlotId.value
      );
      var endTime = endTimetableList.value.find(
        (item) => item.timeSlotId === endTimeSlotId.value
      );
      if (endTime != undefined && startTime.sort > endTime.sort) {
        endTimeSlotId.value = undefined;
      }

      endTimetableList.value = props.endTimetableList.filter(
        (s) => s.sort >= startTime.sort
      );
    }

    // 处理关闭标签的事件
    const handleClose = (
      tagToRemove: { examinerId: string; examinerName: string },
      index: number
    ) => {
      // 从数组中移除标签
      examinerList.value.splice(index, 1);
    };

    const EditExaminer = async () => {
      if (!remark.value) {
        message.error("Save Failed");
        return;
      }

      if (!allocationTaskType.value || !taskNumber.value) {
        message.error("Save Failed");
        return;
      }
      saveLoading.value = true;
      let requestList: ExaminerAllocationManualArrangementInput[] = [];
      var saveExaminerIdList = examinerList.value
        .map((item) => item.examinerId)
        .filter((id) => id !== undefined);
      saveExaminerList
        .filter((s) => saveExaminerIdList.includes(s.examinerId))
        .forEach((examiner: EditExamineModel) => {
          let request = new ExaminerAllocationManualArrangementInput();
          request.examinerId = examiner.examinerId ?? "";
          request.workDate = new Date(props.workDate);
          request.timeWindow = examiner.timeWindow;
          request.taskNumber = taskNumber.value;
          request.taskType = allocationTaskType.value;
          request.isBatch = !single;
          request.remark = remark.value;
          if (allocationTaskType.value === ExaminerAllocationTaskTypeEnum.ICS) {
            request.startTimeSlotId = startTimeSlotId.value;
            request.endTimeSlotId = endTimeSlotId.value;
            if (fullDay) {
              if (examiner.timeWindow == TimeWindowEnum.AM) {
                request.startTimeSlotId = startTimeSlotId.value;
                request.endTimeSlotId =
                  startTimetableList.value[
                    startTimetableList.value.length - 1
                  ].timeSlotId;
              } else {
                request.startTimeSlotId = endTimetableList.value[0].timeSlotId;
                request.endTimeSlotId = endTimeSlotId.value;
              }
            }
          }
          requestList.push(request);
        });

      _Client.examinerAllocationManualArrangementClient
        .addExaminerAllocationManualArrangement(requestList)
        .then((s) => {
          if (s) {
            message.success("Save Successfully");
            context.emit("close", true);
          } else {
            message.error("Save Failed");
          }
        })
        .finally(() => {
          saveLoading.value = false;
        });
    };

    return {
      close,
      examinerList,
      startTimetableList,
      endTimetableList,
      handleClose,
      dates,
      showDatePicker,
      saveLoading,
      selectedFullDay,
      allocationTaskTypeEnum,
      allocationTaskType,
      taskNumber,
      remark,
      startTimeSlotId,
      endTimeSlotId,
      EditExaminer,
      onTimeSlotChange,
      title,
      dayjs,
    };
  },
});
