
import { _Client } from '@/api-client';
import { message } from 'ant-design-vue';
import { LeftOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { DefaultAllocationRuleDto } from '@/api-client/client';
import { defineComponent, getCurrentInstance, inject, onMounted, reactive, ref, toRefs, unref, watch } from "vue";
import kpiDetails from './kpiDetails.vue';
export default defineComponent({
  components: {
    LeftOutlined,
    kpiDetails
  },
  props: {
    score: {
      type: Number || String,
      required: true
    }
  },
  setup(props) {
    const planId = inject('examinerAllocationPlanId', '')
    const showKpiDetailsModal = ref(false)
    const parames = reactive<DefaultAllocationRuleDto>(DefaultAllocationRuleDto.fromJS({
      allocationRuleName: "",
      allocationRuleAbbreviationName: "",
      backupRate: "0"
    }));

    const kpiScore = ref<number | string>('-')

    const cardLoading = ref<DefaultAllocationRuleDto>(new DefaultAllocationRuleDto({ allocationRuleName: "", }));
    onMounted(async () => {
      await getDefaultAllocationRule();
    })

    const getDefaultAllocationRule = async () => {
      let result = await _Client.allocationSettingsClient.getDefaultAllocationRule()
      parames.init(result);
    }

    watch(() => props.score, (val) => {
      kpiScore.value = val
    })

    const kpiDetailsClose = (score: number) => {
      showKpiDetailsModal.value = false
      kpiScore.value = score
    }


    return {
      showKpiDetailsModal,
      parames,
      kpiScore,
      kpiDetailsClose
    }
  }
})
