
import {
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
  onMounted,
} from "vue";
import { CalendarOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import {
  ExaminerAllocationLogInput,
  PageResultOfListOfAllocationLogs,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import Pagination from "@/components/Pagination/index.vue";
import dayjs from "dayjs";

export default defineComponent({
  props: {
    examDayPlanId: {
      type: String,
      required: true,
    },
  },
  components: {
    Pagination,
    CalendarOutlined,
    ExclamationCircleOutlined,
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    const columns = [
      {
        title: "Action",
        dataIndex: "action",
      },
      {
        title: "User",
        dataIndex: "user",
      },
      {
        title: "Time",
        dataIndex: "time",
      },
      {
        title: "Message",
        dataIndex: "message",
      },
    ];

    const dataSource = reactive<PageResultOfListOfAllocationLogs>(
      new PageResultOfListOfAllocationLogs()
    );
    const searchParames = reactive<ExaminerAllocationLogInput>(
      new ExaminerAllocationLogInput({
        pageIndex: 1,
        pageSize: 10,
        examDayPlanId: props.examDayPlanId,
      })
    );
    const tableLoading = ref(false);

    const getList = () => {
      tableLoading.value = true;
      _Client.examDayPlanClient
        .getLogList(searchParames)
        .then((rep) => {
          dataSource.init(rep);
        })
        .finally(() => (tableLoading.value = false));
    };

    onMounted(async () => {
      getList();
    });

    return {
      columns,
      dataSource,
      searchParames,
      tableLoading,
      getList,
      close,
      dayjs,
    };
  },
});
